import React, { useState } from "react";
import { Input } from "@nextui-org/react";
import { confirmAlert } from "react-confirm-alert";

const updateData = (data, id) => {
    const toset = localStorage.getItem('dataModify') ? JSON.parse(localStorage.getItem('dataModify')) : {};
    if (toset == {}) {
      localStorage.setItem('dataModify', JSON.stringify({}));
    }
    if (toset[id] == undefined) {
      toset[id] = {};
    }
    if (toset[id]['display_time'] == undefined) {
      toset[id]['display_time'] = data;
    }
    toset[id]['display_time'] = data;
    localStorage.setItem('dataModify', JSON.stringify(toset));
}

function DiffusionTime({ medias, saveMedia }) {
    const [diffusionTime, setDiffusionTime] = useState(0);
    
    const handleSave = () => {
        if (diffusionTime === 0) {
            confirmAlert({
                title: 'Erreur',
                message: 'Le temps de diffusion ne peut pas être égal à 0',
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
              });
              return;
        }
        
        confirmAlert({
            message: 'Êtes-vous sûr de vouloir modifier le temps de diffusion ?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    medias.map((media) => {
                        updateData(diffusionTime, media.key);
                        });
                    saveMedia(true);
                },
                style: {
                    border: '1px solid',
                    backgroundColor: '#0070f0',
                  },
              },
              {
                label: 'Non',
                onClick: () => {},
                style: {
                    border: '1px solid',
                    backgroundColor: '#0070f0',
                  },
              },
              
            ]
          });
        
    };

    return (
        <div className="flex flex-col items-center justify-center p-2 m-2 space-y-4">
            <Input type="number" placeholder="Temps en secondes" className="w-64"  onChange={(value) => setDiffusionTime(value.target.value)}/>
            <button className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700" onClick={handleSave}>Enregistrer</button>
        </div>
    );
}

export default DiffusionTime;