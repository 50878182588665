import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { LinkIcon, Spinner, useDisclosure } from '@nextui-org/react';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useNavigate } from 'react-router-dom';
import MessageInformations from './Utils/MessageInformations';
import { Loading } from 'react-daisyui';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();
    const currentMail = useMemo(() => {
        const mail = localStorage.getItem('email') || '';
        return mail;
    }, [localStorage.getItem('email')]);
    const navigate = useNavigate();
    
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Check pattern du mail
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setErrorMessage('Veuillez entrer une adresse email valide.');
            setTimeout(() => {
                setErrorMessage('');
            }, 2000);
            return;
        }

        // Envoi mail
        axios.post(process.env.REACT_APP_BASE_URL_API + '/reset-password', {
            email: email
        }).then((response) => {
            confirmAlert({
                message: 'Si un utilisateur avec cette adresse e-mail existe, un lien de réinitialisation vous sera transmis par e-mail.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => navigate('/')
                    }
                ]
            });
        }).catch((error) => {
            confirmAlert({
                message: 'Si un utilisateur avec cette adresse e-mail existe, un lien de réinitialisation vous sera transmis par e-mail.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => navigate('/')
                    }
                ]
            });
        });
    };
    

    return (
        <div className='flex items-center justify-center h-screen'>
            { message === '' ?
            <div className='flex flex-col'>
                <h1 className='mb-4 text-3xl font-bold'>Réinitialisation de mot de passe</h1>
                <form onSubmit={handleSubmit} className='flex flex-col'>
                    <div className='flex flex-col mb-4'>
                        <input type='email' value={email} onChange={handleEmailChange} placeholder='Adresse email'  required className={`p-2 border-2 border-gray-300 rounded-xl ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}  />
                        {errorMessage ? <span className='text-red-500'>{errorMessage}</span> : null }
                     </div>
                    <button type='submit' className='p-2 text-white bg-blue-500 rounded-md'>Envoyer</button>
                </form>
                <div className='flex flex-row items-center justify-center space-x-2'>
                <Link to='/' className='mt-4 text-blue-500' > Retour à la page de connexion</Link>
                </div>
                <p className='mt-4'>{message}</p>
            </div> : <p>{message}</p>
            
    }
        </div>
    );
}

export default ResetPassword;