import { Chip, Select, SelectItem } from '@nextui-org/react';
import React, { useRef, useState } from 'react';


const ScreenTags = ({id, screens, multiSelectionsID}) => {
  console.log(screens)
    const [isAll, setIsAll] = useState(false);
    const [tags, setTags] = useState(screens.length > 0 && !multiSelectionsID ? Array.from(new Set(screens.filter((screen) => screen.active === true).map((screen) => screen.id.toString()))) : []);
    const disabledKeys = useRef(screens.length > 0 ? Array.from(new Set (screens.filter((screen) => !screen.ping).map((screen) => screen.id.toString()))) : []);
    const items = screens.length > 0 ? Array.from(screens.map((screen, index) => {
            return {label: screen.name, value: screen.id, description: ''}
        })) : [];
    
    //if (disabledKeys.current.length != items.length && items.length != 0) items.push({label: "Tous les écrans", value: "all", description: "Tous les écrans"})
    if (items.length > 1) items.push({label: "Tous les écrans", value: "all", description: "Tous les écrans"})
    const updateTags = (newTags) => {
        if (newTags.has('all') && isAll === false){
            setIsAll(true);
            setTags(Array.from(['all']))
            if (multiSelectionsID) {
              multiSelectionsID.map((media) => {
                updateData(Array.from(screens.map((screen) => screen.id)), media.key);
              });
            }
            else {
              updateData(Array.from(screens.filter((screen) => screen.ping ).map((screen) => screen.id )), id);
            }
            return;
        }
        else {
            if (isAll === true) {
                setIsAll(false);
                if (newTags.has('all')) {
                    newTags.delete('all');
                }
                setTags(Array.from(newTags));
                if (multiSelectionsID) {
                  multiSelectionsID.map((media) => {
                    updateData(Array.from(newTags), media.key);
                  });
                }
                else {
                  updateData(Array.from(newTags), id);
                }
                return;
            }
        }
        setTags(Array.from(newTags));
        if (multiSelectionsID) {
          multiSelectionsID.map((media) => {
            updateData(Array.from(newTags), media.key);
          });
        }
        else {
          updateData(Array.from(newTags), id);
        }
    };
    
    const updateData = (data, id) => {
        const toset = localStorage.getItem('dataModify') ? JSON.parse(localStorage.getItem('dataModify')) : {};
        if (toset == {}) {
          localStorage.setItem('dataModify', JSON.stringify({}));
        }
        if (toset[id] == undefined) {
          toset[id] = {};
        }
        if (toset[id]['screen'] == undefined) {
          toset[id]['screen'] = data;
        }
        toset[id]['screen'] = data;
        localStorage.setItem('dataModify', JSON.stringify(toset));
      }
    
  return (
        <Select
        items={items}
        label={"Diffuser vers"}
        variant="bordered"
        isMultiline={true}
        selectionMode="multiple"
        placeholder={items.length > 0 ? "Choix des écrans" : "Aucun écran disponible"}
        labelPlacement="inside"
        selectedKeys={tags}
        //disabledKeys={disabledKeys.current}
        onSelectionChange={(newTags) => updateTags(newTags)}
        classNames={{
          base: "max-w-xs",
          trigger: "min-h-unit-12 py-2",
          background: "#ffba00",
        }}
        renderValue={(tags) => {
          return (
            <div className="flex flex-wrap gap-2 ">
              {tags.map((tag) => (
                <Chip key={tag.key} className='bg-[#ffba00] text-sm'>{tag.textValue}</Chip>
              ))}
            </div>
          );
        }}
      >
        {items.map((items) => (
                <SelectItem key={items.value} textValue={items.label} >
                    <div className="flex flex-col">
                        <span className="text-small">{items.label}</span>
                    </div>
                </SelectItem>
        ))}
        
      </Select>
  );
};

export default ScreenTags;