import { Button, Image, Spinner, useDisclosure } from '@nextui-org/react';
import axios from 'axios';
import { useRef, useMemo, useState } from 'react';
import MessageInformations from './Utils/MessageInformations';
import { confirmAlert } from 'react-confirm-alert';

function ProfileSettings() {
    const clientConfig = useMemo(() => {
        const clientData = localStorage.getItem('client') || '{}';
        return JSON.parse(clientData);
    }, [localStorage.getItem('client')]);

    const profileConfig = useMemo(() => {
        const clientData = localStorage.getItem('profile') || '{}';
        return JSON.parse(clientData);
    }, [localStorage.getItem('profile')]);

    const [firstname, setFirstname] = useState(profileConfig.firstname);
    const [lastname, setLastname] = useState(profileConfig.lastname);
    const username = profileConfig.username;
    const company = clientConfig.societe;
    const [avatar, setAvatar] = useState(profileConfig.avatar);
    const [email, setEmail] = useState(profileConfig.email);
    const [password, setPassword] = useState('');
    const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();
    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
      };
    
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        onOpen();
        axios.post(process.env.REACT_APP_BASE_URL_API + '/upload_profile_avatar', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setAvatar(URL.createObjectURL(file));
            localStorage.setItem('profile', JSON.stringify({firstname: firstname, username: username, lastname: lastname, email: email, company: company, avatar: response.data.url}));
            onClose();
            confirmAlert({
                message: 'Vos informations ont été mises à jour',
                buttons: [
                  {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
            });
            
        }).catch((error) => {
            onClose();
            confirmAlert({
                message: 'Une erreur est survenue lors du téléversement de l\'image',
                buttons: [
                  {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
            });
        });
      };

    const saveProfileInformations = (e) => {
        e.preventDefault();
          axios.put(process.env.REACT_APP_BASE_URL_API + "/profile/update", {
                firstname: firstname,
                lastname: lastname,
                username: username,
                email: '',
                password: password
          },{
            withCredentials: true,
        }).then(() => {
            localStorage.setItem('profile', JSON.stringify({firstname: firstname, lastname: lastname, username: username, company: company, email: email, avatar: avatar}));
            confirmAlert({
                message: 'Vos informations ont été mises à jour',
                buttons: [
                  {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
            });
        }).catch((error) => {
            confirmAlert({
                message: 'Une erreur est survenue lors de la mise à jour de vos informations',
                buttons: [
                  {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
            });
        });
      }
      if (profileConfig === null) {
        return <Spinner className="flex items-center justify-center" label="Loading..." color="warning" />
      }

    return (
        <div className='flex flex-col items-center justify-center h-full lg:space-y-0 lg:space-x-4 lg:flex-row '>
            <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={{content: <Spinner label="Téléversement du média" color="primary" />}} placement="bottom-center" type="info"/>
            <div className='flex flex-col items-center justify-center w-[80%] lg:w-[20%] lg:h-[500px] p-4 lg:p-8 rounded-lg border-2 shadow-lg bg-[#fbfbfb]'>
                <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
                <Image fallbackSrc={require('../avatar.png')} src={avatar ? avatar : require('../avatar.png')} alt="avatar" onClick={handleClick} className="p-2 cursor-pointer w-[200px] h-[200px] mb-2"/>
                <span className='text-sm text-gray-500'>Résolution minimum 400x400</span>
                <Button onClick={handleClick} color="primary" className='mt-2'>Changer l'avatar</Button>
                <div className='flex flex-col items-center justify-center mt-2 '>
                    <p className='text-2xl' >{firstname} {lastname}</p>
                    <p className='font-extralight'>{company}</p>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center w-[80%] lg:w-[50%] lg:h-[500px] rounded-tr-lg rounded-br-lg p-4 lg:p-8 rounded-md border-2 shadow-lg bg-[#fbfbfb]">

                <form className='w-full' onSubmit={saveProfileInformations}>
                <div className="grid w-full gap-2 mb-6 md:grid-cols-2">
                    <div>
                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom</label>
                        <input type="text" id="first_name"  value={firstname} onChange={(e) => setFirstname(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required/>
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prénom</label>
                        <input type="text" id="last_name" value={lastname} onChange={(e) => setLastname(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required/>
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                        <input readOnly type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite" required/>
                    </div>  
                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Changer le mot de passe</label>
                        <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Password"/>
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                    <Button type="submit" color="primary" className="items-center justify-center w-1/3 text-white shadow-md shadow-primary-content">Enregistrer</Button>
                </div>
                </form>
            </div>
        </div>
    )
}

export default ProfileSettings;