import {useEffect, useRef, useState } from 'react';
import React from 'react';
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import axios from 'axios';
import ResetPassword from './components/ResetPassword';
import NewPassword from './NewPassword';


if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

function App () {
    const [isLogged, setIsLogged] = useState(false);
    const initialized = useRef(false);

    useEffect(() => {
      if (initialized.current) return;
      initialized.current = true;
      axios.get(process.env.REACT_APP_BASE_URL_API + "/users/is_logged", { withCredentials: true }).then((response) => {
        if (response.status === 200) {
          setIsLogged(response.data.is_logged);
        }
      }).catch((error) => {
        if (error.status === 401) {
          setIsLogged(false);
        }
      });
    }, [])

    const router = createBrowserRouter([
      {
        path: "/",
        element: isLogged === undefined ? <div></div> : isLogged ? <Dashboard logged={isLogged} setLogged={setIsLogged}/> : <Login logged={isLogged} isLogged={setIsLogged}/>,
      },
      {
          path: "/dashboard/",
          element: isLogged === undefined ? <div></div> : isLogged ? <Dashboard logged={isLogged} setLogged={setIsLogged}/> : <Navigate replace to="/"/>,  
      },
      {
        path: "/reset-password",
        element: isLogged === undefined ? <div></div> : isLogged ? <Navigate replace to="/dashboard"/> : <ResetPassword />,
      },
      {
        path: "/reset/:token",
        element: <NewPassword />,
      }
    ]);

    {/* NEXT UI system */}
    return (

          <RouterProvider router={router} />

    )

 
}
export default App;