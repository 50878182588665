import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Input, Progress} from '@nextui-org/react';
import { PlusIcon } from '../../icons/PlusIcon';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { frCH } from 'date-fns/locale';
import { format, formatDate } from 'date-fns';
import _ from 'lodash';


const allowedExtensions = ['mp4', 'pdf', 'avi', 'mkv', 'mov', 'gif', 'png', 'jpg', 'jpeg']
function validateName(name) {
    var isValidName = true;
    // Check if name doesn't contain special characters
    var extension = name.split('.').pop();
    if (allowedExtensions.indexOf(extension) == -1) isValidName = false;
    return isValidName;
  }

function UploadComponent({files, onClose}){
    const [displayProgress, setDisplayProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const onDrop = useCallback(acceptedFiles => {
        setDisplayProgress(true);
        const formData = new FormData();
        // List all files in list to send it into formData
        acceptedFiles.forEach((file, index) => {
            var extension = file.name.split('.').pop();
            var filename = formatDate(new Date(), 'yyyy_MM_dd_HH_mm', { locale: frCH }) + '_' + (index + Math.floor(Math.random() * (5000 - 10 + 1)) + 10) + '.' + extension;
            const myNewFile = new File([file], filename, {type: file.type});
            if (validateName(file.name) === false)
                alert("Ce format de fichier n'est pas supporté " + file.name + ". Veuillez utiliser un média au format 'mp4', 'avi', 'mkv', 'mov', 'gif', 'png', 'jpg', 'jpeg'");
            else
                formData.append('file', myNewFile);
        });
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = (e) => {
            setProgress((e.loaded / e.total) * 100 - 1);
        };
        xhr.upload.onloadend = () => {
            setProgress(100);
        }
        xhr.open('post', process.env.REACT_APP_BASE_URL_API + '/upload_file');
        xhr.withCredentials = true;
        xhr.send(formData)
        xhr.onload = () => {
            if (xhr.status !== 200) {
                alert('Something went wrong');
            }
            setDisplayProgress(false);
            files();
        };
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) return;
          if (xhr.status !== 200) {
           
          }
          // Update and get the new list of files
          setDisplayProgress(false);
          files();
          onClose();
        };
      }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});
    return (
        <div className="flex items-center justify-center w-full h-full bg-blue" {...getRootProps()}>
                <div  className={`flex flex-col m-4 h-[355px] w-[320px] bg-white border-dashed border-2 rounded-2xl cursor-pointer items-center justify-center ${isDragActive ? 'border-green-500 ': 'border-gray-500'}`} 
                >

                    <input {...getInputProps()} />
                    <span className='flex flex-row p-3 text-white bg-blue-600 border-transparent shadow-none border-1 rounded-xl'>
                    <PhotoIcon className="w-6 h-6 mr-2" color='white'/>
                    Ajouter un média
                    </span>
                    <p className='flex flex-col items-center justify-center m-4 font-bold text-justify'>
                        { isDragActive ?
                            "Déposez vos fichiers ici" :
                            <>
                                <span className='items-center justify-center text-lg font-bold'>ou</span>
                                <p className='items-center justify-center text-lg font-light'>Glissez et déposez vos fichiers ici</p>
                                <span className='items-center justify-center text-xs font-light text-justify'>Formats: mp4, avi, mkv, mov, gif, png, jpg</span>
                                
                            </>
                        }    
                    </p>
                    {
                    displayProgress ? <Progress
                    size="sm"
                    color="primary"
                    value={progress}
                    aria-label="Loading..."
                    showValueLabel={true}
                    className="flex flex-col p-4"
                  /> : null
                }
                </div>
                
        </div>
    )
}

export default UploadComponent;