import React from "react";
import { Input } from "@nextui-org/react";


class TextInput extends React.Component {
 constructor(props) {
  super(props);
  this.textInput = React.createRef();
  this.focusTextInput = this.focusTextInput.bind(this);
}

focusTextInput() {
 this.textInput.current.focus();
}

render() {
 return (
   <div>
    <Input
        type="text"
        defaultValue={this.props.value}
        ref={this.textInput}
        onClick={this.focusTextInput}
        className="w-fit"
        aria-label="Default msg"
    />
  </div>
  );
 }
}
export default TextInput;