import { Link } from "@nextui-org/react";
import axios from "axios";
import { format } from "date-fns";
import { frCH } from "date-fns/locale";
import { set } from "lodash";
import { useState, useEffect, useRef } from "react";
import { MutatingDots } from "react-loader-spinner";

function Apropos() {
    const [isBusy, setBusy] = useState(true)
    // Convertis la date en format YYYY-MM-DD vers DD name-of-month YYYY
    const convertDate = (date) => {
        return format(new Date(date), 'dd MMMM yyyy', { locale: frCH });
    }
    

    useEffect(() => {
        if (localStorage.getItem('licence') !== null) {
            setBusy(false);
            return;
        }
        axios.get(process.env.REACT_APP_BASE_URL_API + "/licence", {
            withCredentials: true,
        }).then((response) => {
            localStorage.setItem('licence', response.data.licence);
            setBusy(false);
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    if (isBusy) return <div className='flex items-center justify-center h-screen'><MutatingDots
    visible={true}
    height="100"
    width="100"
    color="#0070f0"
    secondaryColor="#0070f0"
    radius="12.5"
    ariaLabel="mutating-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
    />
    </div>
    
    return (
        <div className="flex flex-col items-center justify-center w-full h-full ">
            <div className="flex flex-col items-center justify-center w-[80%] p-4 text-white bg-[#0070f0] h-1/3 lg:w-2/3 lg:h-2/3 rounded-3xl">
                {/* Ajoute les informations de licence en cours */}
                <div className="flex flex-col items-center justify-center flex-1">
                    <p className="hidden text-sm font-bold md:block lg:text-2xl">Licence valide jusqu'au: {convertDate(localStorage.getItem('licence'))}</p>
                    <p className="text-sm font-bold md:hidden lg:text-2xl">Expiration: {convertDate(localStorage.getItem('licence'))}</p>
                    <p className="font-bold lg:text-xl">Version 2.1</p>
                    <p className="font-bold lg:text-xl"></p>
                </div>
                <span className="hidden text-sm text-justify md:block">Copyright 2024 - <Link isExternal color="foreground" className="text-white" href={"//https://evoscreen.ch"}> Evo One SA</Link> - Tous droits réservés</span>
            </div>
        </div>
    )
}

export default Apropos;