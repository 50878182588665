import React from 'react';

import { NextUIProvider, Spinner} from '@nextui-org/react';
import AddMedia from './MediaTable/AddMedia';
import Apropos from './Apropos';
import ProfileSettings from './ProfileSettings';
import ScreenDisplay from './ScreenDisplay';
import SideLeftMenu from './SideLeftMenu';
import NavbarFlow from './NavbarFlow';
import Settings from './Settings/Settings';

import {
    useNavigate,
  } from "react-router-dom";
import axios from 'axios';
import { MutatingDots } from 'react-loader-spinner';


export default function Dashboard({logged, setLogged}) {
    const [trigger , setTrigger] = React.useState(true);
    const [component, setComponent] = React.useState('addMedia');
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    
  
    React.useEffect(() => {
      const fetchData = () => {
        try {
          // Simultanément récupérer profile et client, si nécessaire
          if (localStorage.getItem('profile') === null) {
            axios.get(process.env.REACT_APP_BASE_URL_API + "/profile/", {
              withCredentials: true,
            }).then((response) => {
                localStorage.setItem('profile', JSON.stringify({firstname: response.data.firstname, lastname: response.data.lastname, username: response.data.username, company: response.data.company, email: response.data.email, avatar: response.data.avatar}));
              }).catch((error) => {
                if (error.response.status === 404) alert("User not found")
                else alert("Something went wrong") 
            });
          }
          if (localStorage.getItem('client') === null) {
            axios.get(process.env.REACT_APP_BASE_URL_API + "/client/", {
              withCredentials: true,
              }).then((response) => {
                localStorage.setItem('client', JSON.stringify(
                  {
                    'societe': response.data.societe,
                    'adresse': response.data.adresse,
                    'ville': response.data.ville,
                    'npa': response.data.npa,
                    'telephone': response.data.telephone,
                    'email': response.data.email,
                    'site_web': response.data.site_web,
                    'logo_accueil': response.data.logo_accueil,
                    'logo_principal': response.data.logo_principal,
                    'banniere': response.data.banniere,
                  }));
              }).catch((error) => {
                if (error.response.status === 404) alert("User not found")
                else alert("Something went wrong") 
            });
          }
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
          alert("Something went wrong");
        }
      };
    
      fetchData();
    }, []);

    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });
  
    React.useEffect(() => {
      // Handler pour mettre à jour les dimensions
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      // Ajouter l'écouteur d'événement
      window.addEventListener('resize', handleResize);
      
      // Appeler le handler immédiatement pour mettre à jour l'état avec les dimensions initiales
      handleResize();
      
      // Nettoyer l'écouteur d'événement
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
  
    if (logged === undefined || isLoading) {
      return <div className='flex items-center justify-center h-screen'><MutatingDots
      visible={true}
      height="100"
      width="100"
      color="#0070f0"
      secondaryColor="#0070f0"
      radius="12.5"
      ariaLabel="mutating-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
      </div>
    }
    
    if (logged === false) {
      return navigate("/");
    }
    
    return (
      <NextUIProvider>
        
        {windowSize.width > 700 && (
        <div className="hidden min-h-screen md:flex md:flex-row dark:bg-white">
        <SideLeftMenu setMenuTrigger={setTrigger} trigger={trigger} setComponent={setComponent} logged={logged} setLogged={setLogged}/>
        <div className='flex flex-col w-full space-y-8'>
          <NavbarFlow setMenuTrigger={setTrigger} trigger={trigger} logged={logged} setLogged={setLogged} component={component} setComponent={setComponent}/>
          <div className='items-center justify-center flex-1 w-full h-full'>
            {
              component === 'apropos' && <Apropos/>
            }
            {
              component === 'addMedia' && <AddMedia/>
            }
            {
              component === 'profile' && <ProfileSettings/>
            }
            {
              component === 'screen' && <ScreenDisplay/>
            }
            {
              component === 'settings' && <Settings />
            }
          </div>
        </div>
      </div> )}
      {windowSize.width < 700 && (
      <div className='flex flex-col items-center justify-center min-h-screen md:hidden'>
        <div className='flex flex-col p-4 m-4'>
          <p className='flex font-bold text-1xl text-pretty'>Cette application n'est pas disponible sur mobile</p>
        </div>
      </div>)}
    </NextUIProvider>
    )
  }