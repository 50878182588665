import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Input, Button, Checkbox } from '@nextui-org/react';
import { DatePicker, TimePicker  } from 'antd';
import { Tooltip } from 'react-daisyui';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
const { RangePicker } = DatePicker;


const updateData = (value, key, field) => {
  const toset = localStorage.getItem('dataModify') ? JSON.parse(localStorage.getItem('dataModify')) : {};
  if (toset == {}) {
    localStorage.setItem('dataModify', JSON.stringify({}));
  }
  if (toset[key] == undefined) {
    toset[key] = {};
  }
  if (toset[key][field] == undefined) {
    toset[key][field] = value;
  }
  toset[key][field] = value;
  localStorage.setItem('dataModify', JSON.stringify(toset));
}

function format_date(data) {
  if (!data) return [], [], [];
  const date = data['date'] == 0 ? 0 : data['date'] == '-' ? null : data['date'].split('-');
  const hours = data['hours'] == 0 ? 0 : data['hours'] == '-' ? null : data['hours'].split('-');
  const days = data['days'] == 0 ? 0 : data['days'] == '-' ? null : data['days'].split('-').map((day) => parseInt(day));
  return [date, hours, days]

}

const Cron = ({id, save, datas, multiple, updateTable, global}) => {
  //[dayjs(new Date(2024, 0, 23).toLocaleDateString()), dayjs(new Date(2024, 0, 24).toLocaleDateString())]
  const daysOfTheWeek = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  const daysOfTheWeekIndex = [1, 2, 3, 4, 5, 6, 7];
  let data_splited = []
  if (!multiple)
    data_splited = format_date(datas)
  const [selectedDays, setSelectedDays] = useState(global ? [] : data_splited[2] === 0 ? daysOfTheWeekIndex : data_splited[2] === null ? [] : daysOfTheWeekIndex.filter((day) => data_splited[2].includes(day)));
  const [dateInterval, setDateInterval] = useState(global ? [] : data_splited[0] ? [dayjs(data_splited[0][0], "DD/MM/YYYY"), dayjs(data_splited[0][1], "DD/MM/YYYY")] : []);
  const [hourInterval, setHourInterval] = useState(global ? [] : data_splited[1] ? [dayjs(data_splited[1][0], "HH:mm"), dayjs(data_splited[1][1], "HH:mm")] : []);
  const [allDate , setAllDate] = useState(!global && data_splited[0] == 0 && data_splited[0] != '-');
  const [allHours, setAllHours] = useState(!global && data_splited[1] == 0 && data_splited[1] != '-');

  const handleDayChange = (day) => {
    const index = selectedDays.indexOf(day);
    if (index === -1) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleSubmit = () => {

    const dateMessage = allDate ? 'Tout le temps' : dateInterval && dateInterval.length == 2  ? dateInterval[0].format('DD/MM/YYYY') + ' - ' + dateInterval[1].format('DD/MM/YYYY') : ''
    const hourMessage = allHours ? 'Toute la journee' : hourInterval && hourInterval.length == 2 ? hourInterval[0].format('HH:mm') + ' - ' + hourInterval[1].format('HH:mm') : ''
    const daysMessage = selectedDays.length == 7 ? 'Tous les jours' : selectedDays.length > 0 ? selectedDays.map((day) => daysOfTheWeek[day]).join(', ') : ''

            const date = allDate ? '0;' : dateInterval && dateInterval.length == 2 ? dateInterval[0].format("DD/MM/YYYY") + '-' + dateInterval[1].format("DD/MM/YYYY") + ';' : '-;'
            const hour = allHours ? '0;' : hourInterval && hourInterval.length == 2 ? hourInterval[0].format("HH:mm") + '-' + hourInterval[1].format("HH:mm") + ';' : '-;' 
            const days = selectedDays.length == 7 ? '0;' : selectedDays.length > 0 ? selectedDays.join('-') + ';' : '-;'

            if (multiple) {
              multiple.forEach(media => {
                console.log(media.id)
                updateData(date + hour + days, media.key, 'cron');
              });
            }
            else updateData(date + hour + days, id, 'cron');
            if (multiple) save(true);
            else save();
          }
    //const date = 'DD;' + dateInterval[0].get('date') + ';' + dateInterval[0].get('month') + ';' + dateInterval[1].get('year') + ';END;DF;'
    //+ dateInterval[1].get('date') + ';' + dateInterval[1].get('month') + ';' + dateInterval[1].get('year') + ';END;';

    //const hour = 'HD;' + hourInterval[0].get('hour') + ';' + hourInterval[0].get('minute') + ';HF;' + hourInterval[1].get('hour') + ';' + hourInterval[1].get('minute') + ';END;';
    //const days = "D;" + selectedDays.join(';') + ";END;";
    //console.log(date + hour + days);

  return (
    <div className='flex flex-col items-center justify-center space-y-4'>
        <form className='flex flex-col items-center justify-center space-y-4' onSubmit={handleSubmit}>
          <div className='flex flex-row w-full'>
            <RangePicker required defaultValue={data_splited[0] ? [dayjs(data_splited[0][0], "DD/MM/YYYY"), dayjs(data_splited[0][1], "DD/MM/YYYY")] : null} disabled={allDate} className="rounded-br-none rounded-tr-none w-[300px]"  format={'DD/MM/YYYY'} value={dateInterval} locale={locale} onChange={setDateInterval} placeholder={['Date de début', 'Date de fin']}/>
            <div className='border-2 rounded-tr-lg rounded-br-lg w-[200px]'>
                  <Checkbox className="m-2 text-sm" defaultSelected={allDate} checked={allDate} onChange={(e) => setAllDate(e.target.checked)}>Tout le temps</Checkbox>
              </div>
          </div>
          <div className='flex flex-row w-full'>
            <TimePicker.RangePicker  minuteStep={10} allowClear value={hourInterval}  defaultValue={data_splited[1] ? [dayjs(data_splited[1][0], "HH:mm"), dayjs(data_splited[1][1], "HH:mm")] : null}  format="HH:mm" className="rounded-br-none rounded-tr-none w-[300px]" disabled={allHours}  locale={locale} onChange={(value) =>  setHourInterval(value)} placeholder={['Heure de début', 'Heure de fin']}  />
              <div className='border-2 rounded-tr-lg rounded-br-lg w-[200px]'>
                  <Checkbox className="m-2 text-sm" defaultSelected={allHours} checked={allHours} onChange={(e) => setAllHours(e.target.checked)}>Toute la journée</Checkbox>
              </div>
          </div>
          <div className='w-[100%] flex items-center justify-center'>
          <div className='flex flex-row w-full'>
          {daysOfTheWeek.map((day, index) => (
              <Button
              isIconOnly

              className={'m-1 rounded-full'}
              color={(selectedDays.includes(index+1) ? 'success' : 'default')}
                key={index+1}
                onClick={() => handleDayChange(index+1)}
              >
                {day}
              </Button>
            ))}
             <div className='w-[200px]'>
                  <Checkbox className="m-2 text-sm" defaultChecked={selectedDays.length === 7} isSelected={selectedDays.length === 7} onClick={() => {
                    if (selectedDays.length == 7) {
                      setSelectedDays([])
                    }
                    else {
                      setSelectedDays(daysOfTheWeekIndex)
                    }
                    } }>Tous les jours</Checkbox>
              </div>
          </div>
            
          </div>
          <Button onClick={handleSubmit} color="success" className='text-white'>Enregistrer et publier</Button>
        </form>
      
    </div>
  );
};

export default Cron;
