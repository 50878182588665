import React from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Input} from "@nextui-org/react";

export default function MessageInformations({content, title, isOpen, onOpenChange, placement, confirm}) {

  if (!isOpen) return null;

  return (
    <>
      <Modal isOpen={isOpen} size={content.size} isDismissable={content.dismissable === undefined ? true : content.dismissable} onOpenChange={onOpenChange} placement={placement} backdrop="opaque" onClose={() => 
        content.clear === true ? localStorage.setItem('dataModify', JSON.stringify({})) : null
      }>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col items-center justify-center gap-1">{title ? title: content.title}</ModalHeader>
              <ModalBody className="flex flex-col items-center justify-center">
                {content.content}
              </ModalBody>
              <ModalFooter>
                {confirm ?
                <>
                  <Button color="danger" variant="flat" onPress={() => {content.confirm = true; content.onClose()}}>
                    Confirmer
                  </Button>
                  <Button color="primary" onPress={() => {content.confirm = false; content.onClose()}}>
                    Annuler
                  </Button> </>: null
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
