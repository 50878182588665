import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Button, Image, Input, Spinner, useDisclosure } from "@nextui-org/react";
import axios from "axios";
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import MessageInformations from "../Utils/MessageInformations";
import { confirmAlert } from "react-confirm-alert";

export default function Settings () {
    const clientConfig = useMemo(() => {
        const clientData = localStorage.getItem('client') || '{}';
        return JSON.parse(clientData);
    }, [localStorage.getItem('client')]);
    const [pageAccueil, setPageAccueil] = useState(clientConfig.logo_accueil);
    const [logo, setLogo] = useState(clientConfig.logo_principal);
    const [label, setLabel] = useState("Téléversement du média");
    const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();
    const [entreprise, setEntreprise] = useState(clientConfig.societe);
    const [adresse, setAdresse] = useState(clientConfig.adresse);
    const [ville, setVille] = useState(clientConfig.ville);
    const [npa, setNpa] = useState(clientConfig.npa);
    const [telephone, setTelephone] = useState(clientConfig.telephone);
    const [email, setEmail] = useState(clientConfig.email);
    const [siteweb, setSiteweb] = useState(clientConfig.site_web);


    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);

      const handleFileChangeAccueil = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setLabel("Upload de l'image");
        onOpen();
        axios.put(process.env.REACT_APP_BASE_URL_API + '/client/upload_logo_accueil', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setPageAccueil(URL.createObjectURL(file));
            localStorage.setItem('client', JSON.stringify(
                {
                    'societe': entreprise ? entreprise: null,
                    'adresse': adresse ? adresse : null,
                    'ville': ville ? ville : null,
                    'npa': npa ? npa : null,
                    'telephone': telephone ? telephone : null,
                    'email': email ? email : null,
                    'site_web': siteweb ? siteweb : null,
                    'logo_accueil': response.data.url ? response.data.url : null,
                    'logo_principal': logo ? logo : null,
                    'banniere': clientConfig.banniere ? clientConfig.banniere : null,
                }
            ));
            onClose();
            confirmAlert({
                message: 'Votre image a été mise à jour',
                buttons: [
                  {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
            });
        }).catch((error) => {
            confirmAlert({
                message: 'Une erreur est survenue lors du téléversement du média',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {},
                        style: {
                            border: '1px solid',
                            backgroundColor: '#0070f0',
                          },
                    }
                ]
            });
        });
      };

      const handleFileChangePrincipal = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setLabel("Téléversement du média");
        onOpen();
        axios.put(process.env.REACT_APP_BASE_URL_API + '/client/upload_logo_principal', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setLogo(URL.createObjectURL(file));
            localStorage.setItem('client', JSON.stringify(
                {
                    'societe': entreprise ? entreprise: null,
                    'adresse': adresse ? adresse : null,
                    'ville': ville ? ville : null,
                    'npa': npa ? npa : null,
                    'telephone': telephone ? telephone : null,
                    'email': email ? email : null,
                    'site_web': siteweb ? siteweb : null,
                    'logo_accueil': pageAccueil ? pageAccueil : null,
                    'logo_principal': response.data.url ? response.data.url : null,
                    'banniere': clientConfig.banniere ? clientConfig.banniere : null,
                }
                ));
            confirmAlert({
                message: 'Votre image a été mise à jour',
                buttons: [
                    {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                        },
                    }
                ]
            });
        }).catch((error) => {
            confirmAlert({
                message: 'Une erreur est survenue lors du téléversement du média',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {},
                        style: {
                            border: '1px solid',
                            backgroundColor: '#0070f0',
                          },
                    }
                ]
            });
        });
      };

    const saveClientInformations = (e) => {
        e.preventDefault();
        setLabel("Enregistrement...");
        onOpen();
          axios.put(process.env.REACT_APP_BASE_URL_API + "/client/update", {
            adresse: e.target.adresse.value,
            ville: e.target.ville.value,
            npa: e.target.npa.value,
            telephone: e.target.telephone.value,
            email: e.target.email.value,
            site_web: e.target.siteweb.value,
            societe: e.target.entreprise.value,
          },{
            withCredentials: true,
        }).then(() => {
            localStorage.setItem('client', JSON.stringify({
                'adresse': e.target.adresse.value,
                'ville': e.target.ville.value,
                'npa': e.target.npa.value,
                'telephone': e.target.telephone.value,
                'email': e.target.email.value,
                'site_web': e.target.siteweb.value,
                'societe': e.target.entreprise.value,
                'banniere': clientConfig.banniere ? clientConfig.banniere : null,
            }));
            onClose();
            confirmAlert({
                message: 'Vos informations ont été mises à jour',
                buttons: [
                  {
                    label: 'Ok',
                    style: {
                        border: '1px solid',
                        backgroundColor: '#0070f0',
                      },
                  }
                ]
            });
        }).catch(() => {
            onClose()
            confirmAlert({
                message: 'Une erreur est survenue lors de la mise à jour des informations',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {},
                        style: {
                            border: '1px solid',
                            backgroundColor: '#0070f0',
                          },
                    }
                ]
            });
        });
      }
      if (clientConfig === null) {
        return <Spinner className="flex items-center justify-center" label="Loading..." color="warning" />
      }
    
    return (
        <div className='flex items-center justify-center w-[96%] h-full space-x-2 md:flex-row'>
            <div className="flex flex-row ml-4 space-y-10 lg:flex-col">
                <div className="flex flex-col items-center justify-center w-[300px] p-8 bg-white rounded-tr-lg rounded-br-lg shadow-lg lg:border-2 lg:flex-row rounded-xl">
                        <div className='flex flex-col items-center justify-center w-full h-auto rounded-md'>
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChangeAccueil}
                                ref={fileInputRef}
                            />
                            <Image fallbackSrc={require('../../assets/upload.jpg')} src={pageAccueil ? pageAccueil : require('../../assets/upload.jpg')} alt="avatar" onClick={() => fileInputRef.current.click()} className="object-scale-down w-[200px] h-[200px] cursor-pointer"/>
                
                            {/* Resolution conseiller 1920x1080 */}
                            <span className="mb-1 text-xs text-gray-500 dark:text-gray-400">Résolution recommandée 1920x1080</span>
                            <Button onClick={() => fileInputRef.current.click()} color="primary" >Changer l'image d'accueil</Button>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center w-[300px]  p-8 bg-white rounded-tr-lg rounded-br-lg shadow-lg lg:border-2 lg:flex-row  rounded-xl">
                    <div className='flex flex-col items-center justify-center w-full h-auto rounded-md'>
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChangePrincipal}
                            ref={fileInputRef2}
                        />
                        <Image loading="true" fallbackSrc={require('../../assets/upload.jpg')} src={logo != undefined ? logo : require('../../assets/upload.jpg')} alt="avatar" onClick={() => fileInputRef2.current.click()} className="object-scale-down w-[200px] h-[200px] cursor-pointer"/>
                        {/* Resolution conseiller 1920x1080 */}
                        <span className="mb-1 text-xs text-gray-500 dark:text-gray-400">Résolution recommandée 1920x1080</span>
                        <Button onClick={() => fileInputRef2.current.click()} color="primary" >Changer le logo de l'entreprise</Button>
                    </div>
                    </div>
            </div>
            <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={{content: <Spinner label={label} color="primary" />}} placement="bottom-center" type="info"/>
            <div className="flex flex-col items-center justify-center w-full h-[700px] bg-white rounded-tr-lg rounded-br-lg shadow-lg lg:border-2 lg:flex-row  rounded-xl">

                <form className='w-full p-4 m-2 rounded-lg' onSubmit={saveClientInformations}>
                
                <div className="grid w-full gap-2 mb-6 md:grid-cols-2">
                    
                    <div className="col-span-2">
                        <label htmlFor="entreprise" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Entreprise</label>
                        <input type="text" readOnly value={entreprise} id="entreprise" onChange={(e) => setEntreprise(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="John"/>
                    </div>
                    <div className="col-span-2">
                        <label htmlFor="adresse" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adresse</label>
                        <input type="text" id="adresse"  value={adresse} onChange={(e) => setAdresse(e.target.value)}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John"/>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                        <label htmlFor="ville" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ville</label>
                        <input type="text" id="ville" value={ville} onChange={(e) => setVille(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite"/>
                    </div>  
                    <div className="col-span-2 lg:col-span-1">
                        <label htmlFor="npa" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">NPA</label>
                        <input type="text" id="npa" value={npa} onChange={(e) => setNpa(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe"/>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                        <label htmlFor="telephone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Téléphone</label>
                        <input type="tel" id="telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0601020304" pattern="[0-9]{10}" />
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail</label>
                        <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite" />
                    </div>  
                    <div className="col-span-2">
                        <label htmlFor="siteweb" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Site web</label>
                        <input type="text" id="siteweb"  value={siteweb} onChange={(e) => setSiteweb(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>
                </div>
                <Button type="submit" color="primary" className="items-center justify-center w-full text-white shadow-md shadow-primary-content">Enregistrer</Button>
                </form>
            </div>
        </div>
    )
}