import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";

function ScreenDisplay({screen, setComponent}) {
const [img, setImg] = useState();

  //const fetchImage = async () => {
    /*const res = fetch(screen.url + "/screenshot").then((response) => {
      //  return response.blob();
    }).then((blob) => {
        const imageBlob = blob;
        const imageObjectURL = URL.createObjectURL();
        setImg(imageObjectURL);
    }).catch((error) => {
        confirmAlert({
            message: "Impossible de se connecter a l'écran",
            buttons: [
              {
                label: "Ok",
                style: {
                  border: '1px solid',
                  backgroundColor: '#0070f0',
                },
              },
            ],
          });
    });
  };*/

  //useEffect(() => {
   // fetchImage();
  //});


    return (
        <div className="flex w-full h-full border-2 border-white">
            <img src={require('../assets/EvoScreenLogo.png')} className="w-[90%] h-[90%] object-fit" alt="icons" />
        </div>
    )
}

export default ScreenDisplay;