import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDisclosure } from '@nextui-org/react';
import DisplayMedia from './DisplayMedia';
import MessageInformations from '../Utils/MessageInformations';




function AddMedia (props) {
    
    const [mediaList, setMediaList] = useState([]);
    const [content, setContent] = useState('');
    const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));

    const files = (debug=false) => {
        if (debug) return;
        axios.get(process.env.REACT_APP_BASE_URL_API + '/get_file', {withCredentials: true})
        .then((response) => {
            setMediaList(response.data['files']);
        })
        .catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        files();
    }, [])
    //useEffect(() => { files() }, [selectedKeys])

    return (
        <div className="flex flex-col items-center justify-center h-full lg:flex-row">
            <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={content} placement="bottom-center" type="success"/>
            <div className='flex flex-col items-center justify-center w-full h-full'>
                <DisplayMedia medias={mediaList} event={files} isOpen={isOpen}  selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} onClose={onClose} onOpen={onOpen} content={content} setContent={setContent}/>
                <MessageInformations isOpen={isOpen} onOpenChange={onOpenChange} content={content} placement="bottom-center" type="success"/>
            </div>
        </div>
    );
}

export default AddMedia;