import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { redirect, useNavigate, useParams } from 'react-router-dom';

const NewPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Vérifiez si le token est valide ici
        axios.get(process.env.REACT_APP_BASE_URL_API + '/reset/' + token, { withCredentials: true }
      ).then((response) => {
            console.log(response.data.user_id);
      }
      ).catch((error) => {
          setErrorMessage('Le lien de réinitialisation de mot de passe est invalide ou a expiré.');
      });
    }, [])

    const handleChangePassword = (e) => {
        e.preventDefault();
        // Vérifiez si les mots de passe correspondent et effectuez la logique de changement de mot de passe ici
        if (password === confirmPassword) {
            axios.post(process.env.REACT_APP_BASE_URL_API + '/update_password', {password: password, token: token}, {
                withCredentials: true,
            }).then((response) => {
                confirmAlert({
                    title: 'Succès',
                    message: 'Mot de passe changé avec succès.',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => navigate('/')
                        }
                    ]
                });
            }).catch((error) => {
                setErrorMessage('Une erreur s\'est produite lors de la mise à jour du mot de passe.');
            });
            
        } else {
            confirmAlert({
                title: 'Erreur',
                message: 'Les mots de passe ne correspondent pas.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
        }
    };


    if (errorMessage) {
        return (
            <div className='flex items-center justify-center h-screen'>
                <span className='text-red-500'>{errorMessage}</span>
            </div>
        );
    }
    return (
        <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col'>
            <h1 className='mb-4 text-3xl font-bold'>Réinitialisation de mot de passe</h1>
            <form onSubmit={handleChangePassword} className='flex flex-col'>
                <div className='flex flex-col mb-4'>
                    <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Mot de passe'  required className={`p-2 border-2 border-gray-300 rounded-xl ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}  />
                    {errorMessage ? <span className='text-red-500'>{errorMessage}</span> : null }
                </div>
                <div className='flex flex-col mb-4'>
                    <input type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder='Confirmation mot de passe'  required className={`p-2 border-2 border-gray-300 rounded-xl ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}  />
                    {errorMessage ? <span className='text-red-500'>{errorMessage}</span> : null }
                </div>
                <button type='submit' className='p-2 text-white bg-blue-500 rounded-md'>Envoyer</button>
            </form>
            <div className='flex flex-row items-center justify-center space-x-2'>
            <Link to='/' className='mt-4 text-blue-500' > Retour à la page de connexion</Link>
            </div>
        </div>
        </div>
    );
};

export default NewPassword;