import React, { useEffect } from "react";
import { TableHeader, TableColumn, Button, Switch, TableBody, TableRow, TableCell, Chip, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Image, Spinner, Tooltip, Select, SelectItem} from "@nextui-org/react";
import {EditIcon} from "../../icons/EditIcon";
import {DeleteIcon} from "../../icons/DeleteIcon";
import {EyeIcon} from "../../icons/EyeIcon";
import {SearchIcon} from "../../icons/SearchIcon"
import {ChevronDownIcon} from "../../icons/ChevronDownIcon"
import {VerticalDotsIcon} from "../../icons/VerticalDotsIcon"
import {PlusIcon} from "../../icons/PlusIcon"
import {Pagination} from "@nextui-org/react";
import axios from "axios";
import { capitalize } from "../Utils/toolbox/utils";
import { TrashIcon, BookmarkIcon, PhotoIcon, ComputerDesktopIcon, PlayCircleIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { add, set } from "lodash";
import ModalImage from "react-modal-image";
import MessageInformations from "../Utils/MessageInformations";
import UploadComponent from "../Utils/UploadComponent";
import TextInput from "../Utils/TextInput";
import { Checkbox } from "@nextui-org/react";
import { Table } from 'antd';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import {  AdjustmentsHorizontalIcon, CheckIcon, ClockIcon, DocumentCheckIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import ScreenTags from "./ScreenTags";
import Cron from "../Cron/Cron";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import DiffusionTime from "./DiffusionTime";

const statusOptions = [
  {name: "Active", uid: "active"},
  {name: "Disabled", uid: "paused"},
];

const statusColorMap = {
  active: "success",
  paused: "danger",
};

const INITIAL_VISIBLE_COLUMNS = ["APERCU", "MEDIA", "TEMPS", "ECRANS", "ACTIONS"];

const columns2 = [
    {name: "APERCU", uid: "url", sortable: false},
    {name: "MEDIA", uid: "filename", sortable: true},
    {name: "TEMPS", uid: "display_time", sortable: true},
    {name: "ECRANS", uid: "status",  sortable: true},
    {name: "ACTIONS", uid: "actions", sortable: false},
  ];

function DisplayMedia2({medias, event, onOpen, onClose, setContent, selectedKeys, setSelectedKeys}) {
  const [initialCheckbox, setInitialCheckbox] = React.useState({});
  const [filterValue, setFilterValue] = React.useState("");
  const [visibleColumns, setVisibleColumns] = React.useState("all");
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectAll, setSelectAll] = React.useState(false);
  const [ids, setIds] = React.useState([]);
  var id = 0;
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });
  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...medias];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.filename.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(statusFilter).includes(user.status),
      );
    }

    return filteredUsers;
  }, [medias, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const deleteSelections = () => {
    if (window.confirm("Are you sure you want to delete these medias ?") === false) return;
    axios.post(process.env.REACT_APP_BASE_URL_API + '/remove_files', {ids: Array.from(initialCheckbox)}, {withCredentials: true})
    .then((response) => {
    })
  };

  const openPopup = (props) => {
    return (
      <MessageInformations isOpen={true} onOpenChange={onOpen} content={props.content} placement="bottom-center" type="success"/> 
    )
  }

  const renderCell = React.useCallback((item, columnKey, item_id) => {
    const cellValue = item[columnKey];

    const removeMedia = (id, onOpen) => {
      if (window.confirm("Are you sure you want to delete this media ?") === false) return;
      axios.post(process.env.REACT_APP_BASE_URL_API + '/remove_files', {ids: [id]}, {withCredentials: true})
      .then((response) => {
          setContent(
            {
              content: "Média supprimé avec succès",
              size: 'sm'
            }
          );
          onOpen();
          window.location.reload()
      })
    }

    switch (columnKey) {
      case "url":
        return (
          <img className="w-24 h-24 rounded-full cursor-pointer" 
          onClick={() => {onOpen(); setContent({
            content: <Image className="flex w-full h-full" src={cellValue}/>,
            size: '2xl'
            }
          )}}
          src={cellValue} />  
        )
      case "filename":
        return (
            <div className="flex flex-col">
                <p className="text-sm lowercase text-bold">{cellValue}</p>
            </div>
        );
      case "display_time":
        return (
          <TextInput className="w-6" value={cellValue}/>
        );
      case "status":
        return (
          <Switch defaultSelected={cellValue.toLowerCase() ? true : false} color="success"/>
        );
      case "actions":
        return (
          <div className="relative flex items-center justify-end gap-2">
          <Button color="transparent" onClick={() => removeMedia(item.id, onOpen)} aria-label="delete">
            <DeleteIcon className="w-6 h-6" color='red'/>
          </Button>
        </div>
        );
      default:
        return <Checkbox key={item_id}  isSelected={initialCheckbox[item_id]}   onClick={() => {var datas = initialCheckbox; datas[item_id] = !datas[item_id]; setInitialCheckbox(datas); event()}}/>;
    }
  }, [event]);

  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(()=>{
    setFilterValue("")
    setPage(1)
  },[])


  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex items-end justify-between gap-3">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search by name..."
            startContent={<SearchIcon />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                  Status
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={statusFilter}
                selectionMode="multiple"
                onSelectionChange={setStatusFilter}
              >
                {statusOptions.map((status) => (
                  <DropdownItem key={status.uid} className="capitalize">
                    {capitalize(status.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                  Columns
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {capitalize(column.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button startContent={<PlusIcon />} color="success" onPress={() => {
              onOpen();
              setContent({
                content: <UploadComponent onClose={onClose} files={event}/>,
                size: 'sm' 
              });
            }}>Add</Button>  
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-row items-center">
          <span className="flex text-default-400 text-small">Total {medias.length} medias </span>
          <Button className="flex ml-4" hidden={selectedKeys.size === 0} color="primary" onPress={deleteSelections}>Enregistrer</Button>
          <Button className="flex ml-4" hidden={selectedKeys.size === 0} color="danger" onPress={deleteSelections}>Supprimer</Button>
          </div>
          <label className="flex items-center text-default-400 text-small">
            Rows per page:
            <select
              className="bg-transparent outline-none text-default-400 text-small"
              onChange={onRowsPerPageChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    statusFilter,
    visibleColumns,
    onRowsPerPageChange,
    medias.length,
    onSearchChange,
    hasSearchFilter,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="flex items-center justify-between px-2 py-2">
        <span className="w-[30%] text-small text-default-400">
          {selectedKeys === "all"
            ? "All items selected"
            : `${Object.keys(initialCheckbox).length
            } of ${filteredItems.length} selected`}
        </span>
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
        <div className="hidden sm:flex w-[30%] justify-end gap-2">
          <Button isDisabled={pages === 1} size="md" variant="flat" onPress={onPreviousPage}>
            Previous
          </Button>
          <Button isDisabled={pages === 1} size="md" variant="flat" onPress={onNextPage}>
            Next
          </Button>
        </div>
      </div>
    );
  }, [selectedKeys, items.length, page, pages, hasSearchFilter]);
  
  const handleCheckboxClick = () => {
    const updatedCheckboxState = {};
    
    for (const key in initialCheckbox) {
      updatedCheckboxState[key] = !selectAll;
    }
    setInitialCheckbox(updatedCheckboxState);
    setSelectAll(!selectAll);
    event();
  };

  const handleRowClick = (item) => {
  }
  return (
    <Table
      aria-label="Example table with custom cells, pagination and sorting"
      isHeaderSticky
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      className="flex w-full h-full"
      selectionMode="none"
      selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}
      sortDescriptor={sortDescriptor}
      topContent={topContent}
      topContentPlacement="outside"
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={headerColumns}>
        {
          <TableColumn key="selectAll"  align="center" allowsSorting={false}>
            {/* Selectionne toutes les checkbox en mettant leur valeur a true dans le dictionnaire initialCheckbox  */}
            <Checkbox defaultValue={false} isSelected={selectAll} onClick={handleCheckboxClick}/>
          </TableColumn>
        }
        {headerColumns.map((column) => (
          <TableColumn
            key={column.uid}
            align="center"
            allowsSorting={column.sortable}
            isSortable={column.sortable}
          >
            {capitalize(column.name)}
          </TableColumn>
        ))}
      </TableHeader>
      <TableBody emptyContent={"No medias found"} items={sortedItems}>
        {(item) => (
          console.log(item.id),
           setInitialCheckbox({...initialCheckbox, [item.id]: false}),
          <TableRow key={item.id} onRow onClick={() => handleRowClick(item)}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey, item.id)}</TableCell>}
          </TableRow>
        )
        }
      </TableBody>
    </Table>
  );
}


const columns = [
  {
    title: 'Aperçu',
    dataIndex: 'url',
    default: true,
  },
  {
    title: 'Nom du média',
    dataIndex: 'filename',
    default: true,
  },
  {
    title: 'Créé le',
    dataIndex: 'create_at',
    default: false,
  },
  {
    title: 'Ajouté par',
    dataIndex: 'add_by',
    default: false,
  },
  {
    title: 'Temps de diffusion (en s)',
    dataIndex: 'display_time',
    default: true,
  },
  {
    title: 'Écrans',
    dataIndex: 'screen',
    default: true,
  },
  {
    title: 'Actions',
    dataIndex: 'delete',
    default: true,
  },
];









const Row = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};






function isVideo(filename) {
  let extension = filename.split('.').pop();
  if (['mp4', 'avi', 'mov'].includes(extension)) return true;
  return false;
}





export default function DisplayMedia({medias, event, onOpen, onClose, content, setContent, selectedKeys, setSelectedKeys}) {
  {/* Loop on medias and load to data */}
  const [data, setData] = React.useState([]);
  const [multipleSelection, setMultipleSelection] = React.useState(false)
  const [screens, setScreens] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState(columns.filter((column) => column.default == true).map((column) => column.title));
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('screens') && screens.length) return;
      axios.get(process.env.REACT_APP_BASE_URL_API + "/screen", {
        withCredentials: true,
      }).then((response) => {
        setScreens(response.data);
        localStorage.setItem('screens', Math.floor(Math.random() * 1000));
      }).catch((error) => {
        if (error.response.status === 404) alert("No screen found")
        else alert("Something went wrong") 
      });
    }, []);


  useEffect(() => {
    const toset = [];
    medias.map((media) => {
      toset.push({
        key: media.id,
        display_time: <Input type="number" className="w-24"  onChange={(value) => updateData(value.target.value, media.id, 'display_time')} defaultValue={media.display_time}/>,
        filename: media.filename,
        add_by: media.add_by,
        create_at: media.create_at,
        screen: <div className="w-[300px]"><ScreenTags id={media.id} screens={media.screens}/></div>,
        url:
         !isVideo(media.filename) ? 
          <img className="object-scale-down w-24 h-24 cursor-pointer" 
        onClick={() => {onOpen(); setContent({
          content: isVideo(media.filename) ? <ReactPlayer className="flex w-full h-full" url={media.url}  controls/> : <ModalImage className="flex w-full h-full" small={media.url} large={media.url} alt={media.filename}/>,
          size: '2xl'
          }
        )}}
        src={media.url} /> : <Button className="w-24 h-24 bg-transparent" isIconOnly startContent={<PlayCircleIcon className="h-full" color="green"/>} color="success" onClick={() => {onOpen(); setContent({content: isVideo(media.filename) ? <ReactPlayer className="flex w-full h-full" url={media.url}  controls/> : <ModalImage className="flex w-full h-full" small={media.url} large={media.url} alt={media.filename}/>,
        size: '2xl'})}}/>,
        delete: <><Tooltip content="Supprimer le media">
          <Button isIconOnly className="bg-transparent" startContent={<TrashIcon  className="w-6 h-6" color="red" />} onClick={() => removeMedia([media.id])}/>
          </Tooltip>
          <Tooltip content="Planification">
            <Button isIconOnly startContent={<ClockIcon className="w-6 h-6" color="white"/>} color="success" onClick={() => 
              {onOpen(); setContent({
                  content: <Cron id={media.id} updateTable={event} save={saveModifications} datas={media.cron} />,
                  size: '4xl',
                  title: "Planification",
                  dismissable: false
                })
              }
              }/>
            </Tooltip>
            </>
        
      })
    })
    setData(toset);
  }, [medias])
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const removeMedia = (ids) => {
    confirmAlert({
      message: 'Voulez-vous vraiment supprimer les médias sélectionnés ?',
      size: 'lg',
      buttons: [
        {
          label: 'Oui',
          className: 'flex justify-center items-center',
          style: {
            border: '1px solid',
            backgroundColor: '#0070f0',
          },
          onClick: () => {
            setContent(
              {
                content: <Spinner className="flex items-center justify-center" label="Suppression..." color="success" />,
                size: 'sm',
                placement: 'center',
              }
            );
            onOpen();
            axios.post(process.env.REACT_APP_BASE_URL_API + '/remove_files', {ids: ids}, {withCredentials: true})
            .then((response) => {
              onClose();
                setContent(
                  {
                    content: ids.length == 1 ? "Média supprimé avec succès" : "Médias supprimés avec succès",
                    size: 'sm'
                  }
                );
                onOpen();
                //setData(data.filter((media) => !ids.includes(media.key)));
                setTimeout(() => {
                  onClose();
                }, 1500);
                start()
                event();
            })
          }
        },
        {
          label: 'Non',
          onClick: () => {},
          style: {
            border: '1px solid',
            backgroundColor: '#f31260',
          },
        }
      ]
    });
  }

  const updateData = (value, key, field) => {
    const toset = localStorage.getItem('dataModify') ? JSON.parse(localStorage.getItem('dataModify')) : {};
    if (toset == {}) {
      localStorage.setItem('dataModify', JSON.stringify({}));
    }
    if (toset[key] == undefined) {
      toset[key] = {};
    }
    if (toset[key][field] == undefined) {
      toset[key][field] = value;
    }
    toset[key][field] = value;
    localStorage.setItem('dataModify', JSON.stringify(toset));
  }

  const saveModifications = (reload=false) => {
    const toset = localStorage.getItem('dataModify') ? JSON.parse(localStorage.getItem('dataModify')) : {};
    if (Object.keys(toset).length === 0 || toset == {}) {
      setContent(
        {
          content: "Aucun changement n'a été fait.",
          size: 'sm'
        }
      );
      onOpen();
      return;
    }
    setContent(
      {
        content: <Spinner className="flex items-center justify-center" label="Enregistrement..." color="success" />,
        size: 'sm',
        placement: 'center'
      }
    )
    onOpen();
    axios.post(process.env.REACT_APP_BASE_URL_API + '/update_files', {data: toset}, {withCredentials: true})
    .then((response) => {
      onClose();
        setContent(
          {
            content: "Votre contenu a bien été publié.",
            size: 'sm'
          }
        );
        setMultipleSelection(false);
        onOpen();
        localStorage.setItem('dataModify', JSON.stringify({}));
        setTimeout(() => {
          onClose();
        }, 1500);
        if (reload) window.location.reload();
    }).catch((error) => {
      onClose();
      setContent(
        {
          content: "Une erreur s'est produite lors de l'enregistrement des modifications",
          size: 'xl '
        }
      );
      localStorage.setItem('dataModify', JSON.stringify({}));
      onOpen();
    })
    setTimeout(() => {
      event();
    }, 2000);
    
  }

  const hasSelected = selectedRowKeys.length > 0;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setData((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const customEmptyText = <Spinner className="flex items-center justify-center" label="Chargement..." color="success" />;
  return (
    <div className="p-4 m-4" style={{ width: '100%', height: '80vh'}}>
      
      <div className="flex flex-row w-full mb-4">
        <div className="flex flex-row flex-1 space-x-2">
        <span
            className="flex items-end justify-end"
          >
            
          </span>
            <Checkbox className="items-center justify-center ml-1 mr-2 rounded-lg border-1 "  isSelected={multipleSelection} onValueChange={(isSelected) => {setMultipleSelection(isSelected); setSelectedRowKeys([]) }}>
            Sélection multiple
            </Checkbox>
            { hasSelected ?
             <Button className="text-white bg-gray-500" size="md" startContent={<ComputerDesktopIcon className="w-6 h-6" color="white"/> } onClick={() => {
              setContent({
                content: <DiffusionTime medias={data.filter((media) => selectedRowKeys.includes(media.key))} saveMedia={saveModifications} />,
                size: '3xl',
                title: "Sélectionnez le temps de diffusion pour les médias sélectionnés",
                clear: true
              })
              onOpen();
             }} > Temps de diffusion </Button>: null 
          }
          { hasSelected ?
             <Button className="text-white bg-[#ffba00]" size="md" startContent={<ComputerDesktopIcon className="w-6 h-6" color="white"/> } onClick={() => {
              setContent({
                content: <div hidden={!hasSelected} className="flex flex-col space-y-2 items-center justify-center w-[300px]">
                  <ScreenTags updateAll={event} screens={screens} multiSelectionsID={data.filter((media) => selectedRowKeys.includes(media.key))}/>
                  <span className="items-center justify-center pt-4 text-xs text-center">Liste des médias à publier</span>
                  
                  { data.filter((media) => selectedRowKeys.includes(media.key)).map((media) => {
                    return (
                      <div className="flex flex-row items-center justify-center w-full" key={media.key}>
                        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                        {media.filename}
                        </span>
                      </div>
                    )
                    })
                  }
                  <div className="flex items-center justify-center mt-6">
                    <Button color="success"  onClick={() => {saveModifications(true); setMultipleSelection(false); setSelectedRowKeys([])}} className="mt-4 text-white" startContent={<ComputerDesktopIcon className="w-6 h-6" color="white"/> }  loading={loading}>
                      Enregistrer et publier
                    </Button>
                  </div>
                  </div>,
                size: '3xl',
                title: "Sélectionnez les écrans vers lesquelles vous souhaitez diffuser le contenu",
                clear: true
              })
              onOpen();
             }} > Diffuser vers </Button>: null 
          }
          {
            hasSelected ? 
            <Button className="text-white" size="md" startContent={<ClockIcon className="w-6 h-6" color="white"/>} color="success" onClick={() => 
              {onOpen(); setContent({
                  content: <Cron global save={saveModifications} updateTable={event} multiple={data.filter((media) => selectedRowKeys.includes(media.key))} />,
                  size: '4xl',
                  title: "Planification",
                  dismissable: false
                })
              }
              }>Planification</Button> : null
          }
          

          { hasSelected ?
              <Button   size="md" startContent={<TrashIcon className="w-6 h-6" color='white'/>} color="danger" onClick={() => removeMedia(selectedRowKeys)} hidden={!hasSelected} loading={loading}>
                Supprimer
              </Button> : null
          }
        </div>
        <div className="flex items-start justify-start space-x-2">

              <Button size="md" color="primary" onClick={() => {
                    onOpen();
                    setContent({
                      content: <UploadComponent onClose={onClose} files={event}/>,
                      size: 'sm',
                      dismissable: false
                    });
                  }}
                  
                  loading={loading}
                  startContent={<PhotoIcon className="w-6 h-6" color='white'/>}
              >
                Ajouter un média
              </Button>

              <Button size="md" color="success" onClick={() => saveModifications()} className="text-white" startContent={<ComputerDesktopIcon className="w-6 h-6" color="white"/> }  loading={loading}>
                Enregistrer et publier
              </Button>
              <Dropdown>
          <DropdownTrigger className="hidden sm:flex">
            <Button isIconOnly size="md" className="w-8" endContent={<AdjustmentsHorizontalIcon className="w-6 h-6 text-small" color="black" />} variant="flat">
            </Button>
          </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={selectedColumns}
                selectionMode="multiple"
                onSelectionChange={(value) => {setSelectedColumns(value); console.log(selectedColumns)}}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.title}>
                    {capitalize(column.title)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
          </Dropdown>
            
          
        </div>
      </div>
      <DndContext sensors={sensors}  modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={data.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
      <Table

      locale={data === undefined ? { emptyText: customEmptyText } : null}
      components={true ? {} : {
        body: {
          row: Row,
        },
      }}
          bordered   screen={{y: '80%'}} pagination={false} rowSelection={multipleSelection === true ? rowSelection : false} rowKey="key" columns={columns.filter((column) => Array.from(selectedColumns).includes(column.title))} dataSource={data} />
      </SortableContext>
    </DndContext>
    </div>
  );
};